








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import FormBase from '@/shared/classes/form/form-base'
import FormField from '@/shared/components/form/FormField.vue'
import Form from '@/shared/components/form/Form.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: { Form, FormField, PageHeader },
  methods: { __ }
})
export default class Imports extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint('imports/checklists')
    .setFiles(true)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey('file')
        .setTitle(__('admin.views.imports.checklists.form.fields.file'))
        .setMeta({ type: 'xls', accept: 'application/vnd.ms-excel', withoutPreview: true })
    ])
    .setSubmit({
      text: __('admin.views.imports.checklists.form.submit')
    })
    .setOnSuccess(() => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('admin.views.imports.checklists.form.on-success'),
      })

      this.form.setData({})
    })
}
